import React, { createContext, useReducer } from 'react';

export const ProjectStateContext = createContext();
export const ProjectDispatchContext = createContext();

const initialStateBak = {
	clients: [
		{ id: 'bmw', title: 'BMW', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{ id: 'mini', title: 'MINI', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{ id: 'puma', title: 'PUMA', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{ id: 'shiseido', title: 'shiseido', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{
			id: 'narciso',
			title: 'Narciso Rodriguez',
			logo: 'logo-narcisorodriguez.png',
			thumbnail: 'pure-musc.jpg',
			colors: [ '#191816', '#999893', '#FDFDFC' ]
		},
		{ id: 'elle-saab', title: 'Elle Saab', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{
			id: 'issey-miyake',
			title: 'issey miyake',
			logo: '',
			thumbnail: '',
			colors: [ '#406C72', '#844529', '#B79975' ]
		},
		{
			id: 'serge-lutens-digital-consultation-app',
			client: 'Serge Lutens',
			project: 'Digital Consultation App',
			logo: '',
			thumbnail: [ '/images/projects/serge-lutens/sl-1.jpg', '/images/projects/serge-lutens/sl-2.jpg' ],
			colors: [ '#406C72', '#844529', '#B79975' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '',
			type: '', //campaign site, video demo,
			technology: [], // reactjs, css, nodejs, etc ...
			siteUrl: ''
		},
		{
			id: 'van-gogh-sense',
			title: 'Van Gogh Senses',
			logo: 'logo-vangoghsenses.png',
			thumbnail: 'van-gogh-senses.jpg',
			colors: [ '#844529', '#B79975', '#406C72' ]
		},
		{ id: 'yahoo', title: 'Yahoo!', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{
			id: 'qeelin',
			title: 'Qeelin',
			logo: 'logo-qeelin.png',
			thumbnail: 'qeelin.jpg',
			colors: [ '#000000', '#ac1f30', '#303030' ]
		},
		{ id: 'iclick', title: 'ICLICK', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] },
		{ id: 'ortea', title: 'OrTea?', logo: '', thumbnail: '', colors: [ '#406C72', '#844529', '#B79975' ] }
	]
};
const initialState = {
	projects: [
		{
			id: 'serge-lutens-digital-consultation-table',
			client: 'Serge Lutens',
			project: 'Digital Consultation Table',
			logo: '',
			banner: '/images/projects/serge-lutens/sl-1.jpg',
			thumbnail: [ '/images/projects/serge-lutens/sl-1.jpg', '/images/projects/serge-lutens/sl-2.jpg' ],
			colors: [ '#2e1349', '#0f1110', '#ffffff' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2020',
			type: 'Digital Retail Solution', //campaign site, video demo,
			technology: [ 'RFID', 'ReactJS', 'nodejs', 'PWA', 'WebSocket' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'demo video',
			siteUrl: '1'
		},
		{
			id: 'narciso-rodriguez-rouge',
			client: 'narciso rodriguez',
			project: 'ROUGE',
			logo: '',
			banner: '/images/projects/nr/rouge-1.jpg',
			thumbnail: [ '/images/projects/nr/rouge-1.jpg', '/images/projects/nr/rouge-2.jpg' ],
			colors: [ '#e32a15', '#030500', '#7d8286' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2018',
			type: 'campaign-site', //campaign site, video demo,
			technology: [ 'WebRTC', 'Vuejs', 'Vision API' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'campaign site',
			siteUrl: '2'
		},
		{
			id: 'qeelin-site-launch',
			client: 'Qeelin',
			project: 'Site Launch',
			logo: '',
			banner: '/images/projects/qeelin/qeelin-2.jpg',
			thumbnail: [ '/images/projects/qeelin/qeelin-2.jpg', '/images/projects/qeelin/qeelin-1.jpg' ],
			colors: [ '#060606', '#ad1c2f', '#f0b29a' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2015 - Now',
			type: 'corporate site', //campaign site, video demo,
			technology: [ 'PHP', 'HTML5', 'Adaptive Web Design' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'corporate site',
			siteUrl: '3'
		},
		{
			id: 'van-gogh-senses-site-revamp',
			client: 'Van Gogh Senses',
			project: 'Site Revamp',
			logo: '',
			banner: '/images/projects/vangoghsenses/vgs-2.jpg',
			thumbnail: [ '/images/projects/vangoghsenses/vgs-1.jpg', '/images/projects/vangoghsenses/vgs-2.jpg' ],
			colors: [ '#844529', '#B79975', '#406C72' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2018',
			type: 'corporate site', //campaign site, video demo,
			technology: [ 'Wordpress', 'Magneto' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'corporate site',
			siteUrl: '4'
		},
		{
			id: 'narciso-rodriguez-pure-musc',
			client: 'narciso rodriguez',
			project: 'PURE MUSC',
			logo: '',
			banner: '/images/projects/nr/puremusc-01.png',
			thumbnail: [ '/images/projects/nr/puremusc-02.png', '/images/projects/nr/puremusc-01.png' ],
			colors: [ '#ffffff', '#0c0b09', '#676662' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2019',
			type: 'campaign site', //campaign site, video demo,
			technology: [ 'WebRTC', 'Vuejs', 'Vision API' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'campaign site',
			siteUrl: '5'
		},
		{
			id: 'puma-netfit',
			client: 'PUMA',
			project: 'Netfit',
			logo: '',
			banner: '/images/projects/puma/netfit-1.jpg',
			thumbnail: [ '/images/projects/puma/netfit-1.jpg', '/images/projects/puma/netfit-2.jpg' ],
			colors: [ '#433C50', '#D4575A', '#A2A7AB' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2017',
			type: 'campaign site', //campaign site, video demo,
			technology: ['System generated gif' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'campaign site',
			siteUrl: '6'
		},
		{
			id: 'bizhouse',
			client: 'Hysan Development Company Limited',
			project: 'bizhouse',
			logo: '',
			banner: '/images/projects/bizhouse/bizhouse2.png',
			thumbnail: [ '/images/projects/bizhouse/bizhouse2.png', '/images/projects/bizhouse/bizhouse1.jpg' ],
			colors: [ '#9B5D7F', '#EBD9D7', '#FAAE42' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2020',
			type: 'corporate site', //campaign site, video demo,
			technology: ['wordpress'], // reactjs, css, nodejs, etc ...
			buttonTitle: '',
			siteUrl: '7'
		},
		{
			id: 'sauvereign-rebrand-revamp',
			client: 'Sauvereign',
			project: 'Rebrand x Revamp',
			logo: '',
			banner: '/images/projects/sauvereign/sauvereign-1.jpg',
			thumbnail: [
				'/images/projects/sauvereign/sauvereign-1.jpg',
				'/images/projects/sauvereign/sauvereign-2.jpg'
			],
			colors: [ '#D9B68A', '#9AA491', '#4B432D' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2020',
			type: 'corporate site', //campaign site, video demo,
			technology: [ 'laravel' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'corporate site',
			siteUrl: '8'
		},
		{
			id: 'bmw-art-basel',
			client: 'BMW',
			project: 'Art Basel',
			logo: '',
			banner: '/images/projects/bmw/bmw-1.jpg',
			thumbnail: [ '/images/projects/bmw/bmw-1.jpg', '/images/projects/bmw/bmw-2.jpg' ],
			colors: [ '#009ADA', '#FFFFFF', '#000000' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2015',
			type: 'campaign site', //campaign site, video demo,
			technology: [ 'Vanilla JS' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'campaign site',
			siteUrl: '9'
		},
		{
			id: 'mini-f56-launch-party',
			client: 'MINI',
			project: 'F56 Launch Party',
			logo: '',
			banner: '/images/projects/mini/mini-1.jpg',
			thumbnail: [ '/images/projects/mini/mini-1.jpg', '/images/projects/mini/mini-2.jpg' ],
			colors: [ '#CB0000', '#F99700', '#000000' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2014',
			type: [ 'mobile-web-app', 'qrcode-login-system', 'web-photo-app' ],
			technology: [ 'Socket IO', 'Vanilla JS', 'WebRTC' ], // reactjs, css, nodejs, etc ...
			buttonTitle: '',
			siteUrl: '10'
		},
		{
			id: 'puma-do-you-campaign',
			client: 'PUMA',
			project: 'DO YOU Campaign',
			logo: '',
			banner: '/images/projects/puma/puma-2.jpg',
			thumbnail: [ '/images/projects/puma/puma-2.jpg', '/images/projects/puma/puma-1.jpg' ],
			colors: [ '#5D5B59', '#CEA589', '#151414' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2016',
			type: 'web-app', //campaign site, video demo,
			technology: [ 'Graph API', 'WebRTC' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'web app',
			siteUrl: '11'
		},
		{
			id: 'bernice-ma-name-card-redesign',
			client: 'Bernice Ma',
			project: 'Name Card Redesign',
			logo: '',
			banner: '/images/projects/bnc/bnc-1.jpg',
			thumbnail: [ '/images/projects/bnc/bnc-1.jpg', '/images/projects/bnc/bnc-2.jpg' ],
			colors: [ '#AB7E4D', '#E4DAD2', '#524028' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2020',
			type: 'graphic-design', //campaign site, video demo,
			technology: [], // reactjs, css, nodejs, etc ...
			buttonTitle: 'preview',
			siteUrl: '12'
		},
		{
			id: 'yahoo-big-idea-chair-awards',
			client: 'Yahoo!',
			project: 'Big Idea Chair Awards',
			logo: '',
			banner: '/images/projects/yahoo/yahoo-2.jpg',
			thumbnail: [ '/images/projects/yahoo/yahoo-2.jpg', '/images/projects/yahoo/yahoo-1.jpg' ],
			colors: [ '#7b0099', '#E5E1D9', '#984970' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '2015',
			type: 'campaign-site', //campaign site, video demo,
			technology: [ 'AngularJS' ], // reactjs, css, nodejs, etc ...
			buttonTitle: 'camapign site',
			siteUrl: '13'
		},
		/* {
			id: '',
			client: '',
			project: '',
			logo: '',
			banner: '/images/projects/',
			thumbnail: [ '', '' ],
			colors: [ '', '', '' ],
			slides: [],
			description:
				'<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam labore aperiam corporis reiciendis omnis repellendus quaerat sed, magni eveniet in debitis assumenda iure aliquid excepturi suscipit, ducimus, accusamus nesciunt molestias.</p>',
			year: '',
			type: '', //campaign site, video demo,
			technology: [], // reactjs, css, nodejs, etc ...
			buttonTitle: '',
			siteUrl: ''
		} */
	]
};
const reducer = (state, action) => {
	if (action.type === 'GET_PROJECT_BY_ID') {
		const projects = [ ...state.projects ];
		const current = projects.findIndex((project) => project.id === action.payload);
		if (current <= 0) return { ...state };

		const preArray = projects.slice(0, current);
		const postArray = projects.slice(current);
		const newProjects = [ ...postArray, ...preArray ];
		return { ...state, projects: newProjects };
	}
};

const ProjectsContextProvider = ({ children }) => {
	const [ state, dispatch ] = useReducer(reducer, initialState);
	return (
		<ProjectStateContext.Provider value={state}>
			<ProjectDispatchContext.Provider value={dispatch}>{children}</ProjectDispatchContext.Provider>
		</ProjectStateContext.Provider>
	);
};

export default ProjectsContextProvider;
