import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ArrowIcon from './arrow-left.inline.svg';

const Header = ({ siteTitle, onMenuClicked }) => (
	<header className={styles.header}>
		<h1 className={styles.siteLogo}>
			{/* <Link to="/" className={`clickable ${styles.navLink}`}>
				H.
			</Link> */}
			<a href="/" className={`clickable ${styles.navLink}`}>
				H.W.
			</a>
			<a href="/" className={styles.btnBackHome}>
				<span>BACK</span>
				<ArrowIcon/>
			</a>
		</h1>
		<div
			className={`clickable ${styles.mainNav}`}
			onClick={() => {
				onMenuClicked();
			}}
		>
			<div />
			<div />
			<div />
		</div>
		{/* <div className={classNames(styles.nav, styles.topNav)}>
			<Link className={styles.navLink} to="/">
				HOME
			</Link>
		</div>
		<div className={classNames(styles.nav, styles.leftNav)}>
			<Link className={classNames(styles.navLink, styles.leftNav__link)} to="/project">
				WORKS
			</Link>
		</div>
		<div className={classNames(styles.nav, styles.bottomNav)}>
			<Link className={styles.navLink} to="/">
				ABOUT
			</Link>
		</div>
		<div className={classNames(styles.nav, styles.rightNav)}>
			<Link className={classNames(styles.navLink, styles.rightNav__link)} to="/">
				MUSIC
			</Link>
		</div> */}
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
