import React, { Fragment, useState, useMemo, useRef, Children, isValidElement, cloneElement } from 'react';
import { useSprings, animated } from 'react-spring';
import { useGesture, useDrag, useWheel } from 'react-use-gesture';
import clamp from 'lodash.clamp';
import swap from 'lodash-move';
import { navigate } from 'gatsby-link';
import ArrowRightIcon from '../projects/arrow-right.inline.svg';

const shiftArray = (array, cutOffIndex) => {
	const preArray = array.slice(0, cutOffIndex);
	const postArray = array.slice(cutOffIndex);
	const newArray = [ ...postArray, ...preArray ];
	return newArray;
};

const rearrangeProjects = (originalProjects, currentProjectIndex, currentStep, isInTransition, isLoop) => {
	const projects = [ ...originalProjects ];
	let projectsSubset = [];
	let direction = 0;
	if (isInTransition) {
		if (currentStep === 0) direction = 1;
		if (currentStep === 2) direction = -1;
	}
	const cutOffIndex = 5 - (isInTransition ? currentProjectIndex + direction : currentProjectIndex) % 5;
	let projectIndex = isInTransition
		? currentProjectIndex % projects.length + direction
		: currentProjectIndex % projects.length;
	projectIndex = projectIndex < 0 ? projects.length - 1 : projectIndex;
	projectIndex = projectIndex > projects.length - 1 ? 0 : projectIndex;

	if (projectIndex + 5 <= projects.length) {
		projectsSubset = projects.slice(projectIndex, projectIndex + 5);
	} else {
		projectsSubset = projects.slice(projectIndex, projects.length);
		const fillInProjects = projects.slice(0, 5 - (projects.length - projectIndex));
		projectsSubset = [ ...projectsSubset, ...fillInProjects ];
	}
	// console.log(projects[projectIndex]);
	// if (isLoop) navigate('/projects/' + projects[projectIndex].id);
	if (typeof window !== 'undefined' && document) {
		if (isLoop) window.history.pushState({}, null, '/projects/' + projects[projectIndex].slug);
	}
	return shiftArray(projectsSubset, cutOffIndex);
};

const AnimatedGridLayer = ({
	bind,
	props,
	onMouseClick,
	reverse,
	children,
	status,
	isVisible,
	projects,
	projectIndex
}) => {
	const isCurrentLayer = (index, { loop, orders, step }) => {
		return loop ? index === orders.current[step.current ? 1 : 0] : index === step.current;
	};
	const isNextLayer = (index, { loop, orders, step }) => {
		const prevIndex = index ? index - 1 : orders.current.length - 1;
		return loop ? prevIndex === orders.current[step.current ? 1 : 0] : prevIndex === step.current;
	};
	const numOfChildren = Children.count(children);

	const childrenWithProjectData = useMemo(
		() => {
			const arrangedProjects = rearrangeProjects(
				projects,
				projectIndex.current,
				status.step.current,
				status.inTransition.current,
				status.loop
			);

			return onMouseClick
				? Children.map(children, (child, index) => {
						const props = {
							onMouseClick,
							isVisible,
							isCurrentLayer: isCurrentLayer(index, status),
							inTransition: status.inTransition.current,
							key: projects.length ? arrangedProjects[index].id : index,
							project: projects.length ? arrangedProjects[index] : {}
						};
						if (isValidElement(child)) {
							return cloneElement(child, props);
						}
						return child;
					})
				: Children.toArray(children);
		},
		[ status, isVisible, projects, projectIndex, children, onMouseClick ]
	);
	return (
		<animated.section {...bind()} className={`g-grid ${reverse ? 'reverse' : ''}`}>
			{props.map(({ scale: s, zIndex, visibility }, i) => (
				<animated.div
					key={i}
					className={`g-grid__layer ${reverse ? 'reverse' : ''} ${isCurrentLayer(i, status)
						? 'current'
						: ''} ${isNextLayer(i, status) ? 'next' : ''} ${status.inTransition.current
						? 'in-transition'
						: ''}`}
					style={{
						transform: s.interpolate(
							(s) => `
							matrix3d(
								${s},
								0,
								0.00,
								0,
								0.00,
								${s},
								0.00,
								0,
								0,
								0,
								1,
								0,
								0,
								0,
								0,
								1
							)
						`
						),
						zIndex,
						visibility
					}}
				>
					{i < numOfChildren ? (
						<Fragment>
							{childrenWithProjectData[i]}
							<div aria-hidden="true" className="g-grid__trigger" onMouseDown={onMouseClick('next')}>
								<h3 className="g-grid__trigger-title">NEXT</h3>
								<h6 className="g-grid__trigger-subtitle">project</h6>
								<div className="g-grid__trigger-arrow"><ArrowRightIcon/></div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="abcdefg">
								<div className="g-grid__item a" />
								<div className="g-grid__item b" />
								<div className="g-grid__item c" />
								<div className="g-grid__item d" />
								<div className="g-grid__item e" />
								<div className="g-grid__item f" />
								<div className="g-grid__item g" />
								<div className="g-grid__item h" />
								<div className="g-grid__item i" />
							</div>
							<div aria-hidden="true" className="g-grid__trigger" onMouseDown={onMouseClick('next')} />
						</Fragment>
					)}
				</animated.div>
			))}
		</animated.section>
	);
};

const Sections = ({ children, start = 0, loop = true, reverse = false, isVisible = true, projects = [] }) => {
	const PHI = 0.618033989; // golden ratio conjugate
	const LAYERS = loop ? 5 : Children.count(children); // 5 layers for smooth transition
	const projectIndex = useRef(0);
	const orders = useRef([ ...new Array(LAYERS) ].map((_, index) => index));
	const step = useRef(start);
	const timeout = useRef();
	const inTransition = useRef(false); // lock user interaction
	const [ status, setStatus ] = useState({ loop, step, orders, inTransition });
	// set useSpring props
	const setProps = (y = 0, immediate = false) => (i) => {
		const currentPosition = orders.current.indexOf(i);

		const baseExponent = currentPosition - step.current;
		const dragExponent = Math.round((y / 180 + Number.EPSILON) * 100) / 100; // (0 - 1)
		const exponent = baseExponent + dragExponent;

		// scale formula : Math.pow(fibonacci scale, exponent)
		const scale = Math.pow(PHI, exponent * 4);
		const zIndex = currentPosition * 10;
		return { scale, zIndex, immediate };
	};
	// setup springs
	const [ springs, setSpring ] = useSprings(LAYERS, setProps());

	const onClickHandler = (direction) => (e) => {
		let y = 0;

		if (direction === 'next') {
			step.current = step.current < LAYERS - 1 ? step.current + 1 : step.current;
			projectIndex.current = projectIndex.current + 1;
		}
		if (direction === 'prev') {
			step.current = step.current > 0 ? step.current - 1 : step.current;
			projectIndex.current = projectIndex.current - 1;
		}
		inTransition.current = true;

		resetOrder();
		setSpring(setProps(y, false));
	};

	// for mobile
	const onDragHandler = ({ down, movement: [ , my ], distance, cancel }) => {
		let y = down ? clamp(distance * Math.sign(my), -300, 300) : 0;
				
		if (!inTransition.current && down && Math.abs(y) > 150) {
			cancel();

			const goNext = step.current - Math.sign(my) >= 0;
			step.current = goNext ? step.current - Math.sign(my) : 0;
			inTransition.current = true;
			y = 0;

			resetOrder();
		}

		setSpring(setProps(y, false));
	};

	// for desktop
	const onWheelHander = ({ delta: [ , dy ], memo: [ my, md, df ] = [ 0, 0, 0 ] }) => {
		// dy = current delta y
		// my = accumulated delta y ????
		// md = previous delta y
		// df = decelerating frames (accumulated)
		let y = my;
		// wheel is decelerating if previous dy is greater than current dy
		const isDecelerating = Math.sign(dy) === Math.sign(md) && Math.abs(dy) - Math.abs(md) <= 0; // Math.abs(dy) - Math.abs(md) <= 0
		// add decelerating frames if current frame is decelerating
		// only count frame when dy < md ( don't count when dy === md )
		// reset to zero when wheel is not decelerate
		const deceleratingFrames = isDecelerating ? df + (Math.abs(dy) - Math.abs(md) ? 1 : 0) : 0;
		const trulyDecelerating = deceleratingFrames > 20 || (deceleratingFrames > 0 && Math.abs(dy) < 2);
		// console.log('t d: '+trulyDecelerating, deceleratingFrames, isDecelerating, dy, my, md, df )
		if (trulyDecelerating) {
			// inTransition.current = false
			// if (!inTransition.current)
			y = 0;
		} else {
			if (!inTransition.current) {
				y = clamp(y - dy, -200, 200);
				// console.log(y)

				if (Math.abs(y) > 180) {
					// console.log('%creset', 'color: red; font-family:monospace; font-size: 60px')
					// console.log("%cThis is a custom font style","color: blue; font-family:monospace; font-size: 20px");
					// go to next page and lock mouse wheel
					const goNext = step.current - Math.sign(y) >= 0;
					step.current = goNext ? step.current - Math.sign(y) : 0;

					inTransition.current = true;
					y = 0;
					resetOrder();
					// reset
				}
			}
		}

		setSpring(setProps(y, false));
		// console.log('---------');
		return [ y, dy, deceleratingFrames ];
	};

	const resetOrder = () => {
		setStatus((status) => ({ ...status, inTransition }));
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			if (loop && (step.current === 0 || step.current === 2)) {
				const newOrder =
					step.current === 2
						? swap(orders.current, 0, orders.current.length - 1)
						: swap(orders.current, orders.current.length - 1, 0);
				orders.current = newOrder;
				step.current = step.current === 2 ? step.current - 1 : step.current + 1;
				setSpring(setProps(0, true));
			}
			inTransition.current = false;
			setStatus((status) => ({ ...status, step, orders, inTransition }));
		}, 1200);
	};

	const bind = useGesture({
		// onDrag: onDragHandler
		// onWheel: onWheelHander
	});

	return (
		<Fragment>
			{children && (
				<AnimatedGridLayer
					bind={loop ? bind : () => {}}
					props={springs}
					onMouseClick={onClickHandler}
					reverse={reverse}
					children={children}
					status={status}
					isVisible={isVisible}
					projects={projects}
					projectIndex={projectIndex}
				/>
			)}
		</Fragment>
	);
};

export default Sections;
