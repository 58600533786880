import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './cursor.scss';

const isMobile = () => {
	const ua = navigator.userAgent;
	return /Android|Mobi/i.test(ua);
};

const Cursor = () => {
	
	const [ position, setPosition ] = useState({ x: 0, y: 0 });
	const [ clicked, setClicked ] = useState(false);
	const [ cursorInverted, setCursorInverted ] = useState(false);
	const [ linkHovered, setLinkHovered ] = useState(false);
	const [ linkHoveredInverted, setLinkHoveredInverted ] = useState(false);
	const [ hidden, setHidden ] = useState(false);
	
	

	useEffect(() => {
		addEventListeners();
		handleLinkHoverEvents();
		return () => removeEventListeners();
	}, []);

	const addEventListeners = () => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseenter', onMouseEnter);
		document.addEventListener('mouseleave', onMouseLeave);
		document.addEventListener('mousedown', onMouseDown);
		document.addEventListener('mouseup', onMouseUp);
	};

	const removeEventListeners = () => {
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseenter', onMouseEnter);
		document.removeEventListener('mouseleave', onMouseLeave);
		document.removeEventListener('mousedown', onMouseDown);
		document.removeEventListener('mouseup', onMouseUp);
	};

	const onMouseMove = (e) => {
		setPosition({ x: e.clientX, y: e.clientY });
	};

	const onMouseDown = () => {
		setClicked(true);
	};

	const onMouseUp = () => {
		setClicked(false);
	};

	const onMouseLeave = () => {
		setHidden(true);
	};

	const onMouseEnter = () => {
		setHidden(false);
	};

	const handleLinkHoverEvents = () => {
		document.querySelectorAll('a, div.g-grid__full .g-grid__trigger, div.clickable ').forEach((el) => {			
			el.addEventListener('mouseover', () => setLinkHovered(true));
			el.addEventListener('mouseout', () => setLinkHovered(false));
		});

		document.querySelectorAll('div.clickable.inverted, h4.clickable.inverted').forEach((el) => {			
			el.addEventListener('mouseover', () => setLinkHoveredInverted(true));
			el.addEventListener('mouseout', () => setLinkHoveredInverted(false));
		});

		document.querySelectorAll('div.cursor-inverted').forEach((el) => {			
			el.addEventListener('mouseover', () => setCursorInverted(true));
			el.addEventListener('mouseout', () => setCursorInverted(false));
		});
	};

	const cursorClasses = classNames('cursor', {
		'cursor--inverted': cursorInverted,
		'cursor--clicked': clicked,
		'cursor--hidden': hidden,
		'cursor--link-hovered': linkHovered,
		'cursor--link-hovered-inverted': linkHoveredInverted
	});
	
	return (typeof navigator !== 'undefined' && isMobile()) ? null : <div className={cursorClasses} style={{ left: `${position.x}px`, top: `${position.y}px` }} />;

};

export default Cursor;
