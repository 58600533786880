/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, webgl }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						description
						image
						title
						twitterUsername
						url
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={site.siteMetadata.title}
			// titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: site.siteMetadata.title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:url`,
					content: site.siteMetadata.url
				},
				{
					property: `og:image`,
					content: site.siteMetadata.image
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.twitterUsername
				},
				{
					name: `twitter:title`,
					content: site.siteMetadata.title
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			].concat(meta)}
		>
			<script>
				{webgl &&
					`HTMLCanvasElement.prototype.getContext = (function(origFn) {
            var bannedTypes = {
              "webgl": true,
              "webgl2": true,
              "experimental-webgl":, true
            };
            return function() {
              var type = arguments[0];
              return bannedTypes[type]
                 ? null
                 : origFn.apply(this, arguments);
            };
          }(HTMLCanvasElement.prototype.getContext));`}
			</script>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired
};

export default SEO;
