import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './loading.scss';

const Loading = ({ sections, hideLoading }) => {
	const master = {
		hidden: {
			transition: {
				when: 'afterChildren'
			},
			transitionEnd: {
				display: 'none'
			}
		},
		show: {
			display: 'block'
		}
	};
	const container = {
		hidden: ({ section, index }) => ({
			transition: {
				delay: section === 'Projects' ? 0 : 1.0,
				staggerChildren: 0.4,
				staggerDirection: -1,
				when: 'afterChildren'
			},
			transitionEnd: {
				opacity: 0
			}
		}),
		show: ({ section, index }) => ({
			opacity: 1,
			transition: {
				delay: section === 'Projects' ? 0 : 2.0,
				staggerChildren: 0.4
			}
		})
	};
	const item = {
		hidden: { opacity: 0 },
		show: { opacity: 1 }
	};

	const onStart = () => {
		// if (document.querySelector('.g-grid__wide')) document.querySelector('.g-grid__wide').scrollTop = 0;
		// if (document.querySelector('.g-grid__narrow')) document.querySelector('.g-grid__narrow').scrollTop = 0;
	};
	const [ hidden, setHidden ] = useState(false);
	useEffect(
		() => {
			setHidden(hideLoading);
		},
		[ hideLoading ]
	);
	return (
		<motion.div
			className="loading-master"
			variants={master}
			initial="show"
			// animate={hidden ? 'hidden' : 'show'}
			exit="hidden"
			onAnimationStart={onStart}
		>
			{sections.map((section, index) => (
				<motion.div
					key={index}
					// custom={{ section, index }}
					custom={{ section: 'Home', index }}
					variants={container}
					className="loading g-grid__full"
				>
					<div className="g-grid loading ">
						<div className="g-grid__layer current" />
						<div className="abcdefg">
							<motion.div className="g-grid__item a" variants={item} />
							<motion.div className="g-grid__item b" variants={item} />
							<motion.div className="g-grid__item c" variants={item} />
							<motion.div className="g-grid__item d" variants={item} />
							<motion.div className="g-grid__item e" variants={item} />
							<motion.div className="g-grid__item f" variants={item} />
							<motion.div className="g-grid__item g" variants={item} />
						</div>
						<div className="g-grid__trigger" />
					</div>
				</motion.div>
			))}
		</motion.div>
	);
};

export default Loading;
