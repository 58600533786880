import React, { useRef } from 'react';
import styles from './sitemap.scss';

const Sitemap = ({ closeMenu }) => {
	const sitemap = useRef([
		{ slug: 'about', url: '/#about-page' },
		// { slug: 'skills', url: '/#about-page?s=skills' },
		{ slug: 'works', url: '/#works-page' },
		{ slug: 'projects', url: '/#projects-page' },
		{ slug: 'contact', url: '/#contact-page' }
	]);
	return (
		<div className="sitemap__sections">
			<nav className="sitemap__sections-menu">
				{sitemap.current.map((section, index) => (
					<div key={index} className="sitemap__sections-menu__item">
						<a
							className="sitemap__sections-menu__item-inner"
							onClick={() => {
								window.location.href = section.url;
								closeMenu();
							}}
						>
							{section.slug}
						</a>
					</div>
				))}
			</nav>
		</div>
	);
};

export default Sitemap;
