import React from 'react'

const Billboard = () => {
    return (
        <div className="billboard sitemap__billboard">
            <div className="billboard__text">
                <h5 className="billboard__subtitle"></h5>
                <h1 className="billboard__title">Menu</h1>
                <h3 className="billboard__caption">                    
                    suit<br/>yourself
                </h3>
            </div>

        </div>
    )
}

export default Billboard
