/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useMemo, Children, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './Header';
import Loading from './loading';
import ProjectsContextProvider from '../context/ProjectsContextProvider';
import Cursor from './cursor';
import Menu from './Menu';
// import Loading from "./loading"
// import styles from "./loading.scss"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	const sections = useMemo(
		() => {
			const sectionsComponent = Children.toArray(children).filter((c) => c.props.hasOwnProperty('children'))[0]; //c.type.name === 'Sections')[0];
			const sectionsChildren = Array.isArray(sectionsComponent.props.children)
				? sectionsComponent.props.children
				: [ sectionsComponent.props.children ];
			return sectionsChildren.map((s) => s.type.displayName);
		},
		[ children ]
	);
	const [ isMenuOpened, setIsMenuOpened ] = useState(false);
	const [ isLoadCompleted, setIsLoadCompleted ] = useState(false);
	const toggleMenu = (e) => void setIsMenuOpened((v) => !v);

	return (
		<main id="main">
			<Header siteTitle={data.site.siteMetadata.title} onMenuClicked={toggleMenu} />
			<ProjectsContextProvider>
				<main>{children}</main>
			</ProjectsContextProvider>
			{/* <Loading sections={sections} hideLoading={isLoadCompleted} /> */}
			<Menu sections={sections} showMenu={isMenuOpened} />
			<Cursor />
		</main>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
