import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Billboard from './Billboard';
import Sitemap from './Sitemap';
import styles from './menu.scss';

const Menu = ({ sections, showMenu }) => {
	const master = {
		hidden: {
			transition: {
				when: 'afterChildren'
			},
			transitionEnd: {
				display: 'none'
			}
		},
		show: {
			display: 'block'
		}
	};
	const container = {
		hidden: ({ index }) => ({
			transition: {
				delay: index === 0 ? 0 : 1.0,
				staggerChildren: 0.2,
				staggerDirection: -1,
				when: 'afterChildren'
			},
			transitionEnd: {
				opacity: 0
			}
		}),
		show: ({ index }) => ({
			opacity: 1,
			transition: {
				delay: index === 0 ? 0 : 2.0,
				staggerChildren: 0.2
			}
		})
	};
	const item = {
		hidden: { opacity: 0 },
		show: { opacity: 1 }
	};

	const onStart = () => {
		// if (document.querySelector('.g-grid__wide')) document.querySelector('.g-grid__wide').scrollTop = 0;
		// if (document.querySelector('.g-grid__narrow')) document.querySelector('.g-grid__narrow').scrollTop = 0;
		// console.log('onStart:', showMenu);
		if (document.getElementById('main') && showMenu) document.getElementById('main').classList.add('menu-opened');
	};
	const onEnd = () => {
		// console.log('onEnd:', showMenu);
		if (document.getElementById('main') && !showMenu) document.getElementById('main').classList.remove('menu-opened');
	};
	const [ show, setShow ] = useState(false);

	useEffect(
		() => {
			setShow(showMenu);
		},
		[ showMenu ]
	);

	return (
		<motion.div
			className="sitemap__menu-master"
			variants={master}
			initial="hidden"
			animate={show ? 'show' : 'hidden'}
			// exit="hidden"
			onAnimationStart={onStart}
			onAnimationComplete={onEnd}
		>
			{sections.map((section, index) => (
				<motion.div key={index} custom={{ index }} variants={container} className="sitemap__menu g-grid__full">
					<div className="g-grid sitemap__menu ">
						<div className="g-grid__layer current">
							<div className="abcdefg">
								<motion.div className="g-grid__item a" variants={item}>
									{index === 0 ? <Sitemap closeMenu={() => setShow(false)} /> : null}
								</motion.div>
								<motion.div className="g-grid__item b" variants={item}>
									{index === 0 ? <Billboard /> : null}
								</motion.div>
								<motion.div className="g-grid__item c" variants={item} />
								<motion.div className="g-grid__item d" variants={item} />
								<motion.div className="g-grid__item e" variants={item} />
								<motion.div className="g-grid__item f" variants={item} />
								<motion.div className="g-grid__item g" variants={item} />
							</div>
							<div className="g-grid__trigger" />
						</div>
					</div>
				</motion.div>
			))}
		</motion.div>
	);
};

export default Menu;
